@import "../../layout/app/colors";

.entry-card-wrapper {
  padding: 16px;
  border-radius: 4px;
  background: linear-gradient(200deg, #2B3943 0%, rgba(43, 57, 67, 0.5) 100%);
  &.large {
    padding-top: 24px;
  }
  &.ethereum { background: linear-gradient(210.01deg, rgba(105, 125, 188, 0.2) 7.38%, rgba(73, 88, 155, 0.2) 91.91%)i; }
  &.optimism { background: linear-gradient(210.17deg, rgba(200, 43, 149, 0.15) 7.34%, rgba(233, 52, 51, 0.15) 91.95%);; }
  &.arbitrum { background: linear-gradient(180deg, rgba(80, 158, 234, 0.2) 0%, rgba(49, 127, 203, 0.2) 100%); }

  &.makerdao { background: linear-gradient(210.17deg, rgba(26, 171, 155, 0.2) 7.34%, rgba(12, 184, 193, 0.2) 91.95%); }
  &.aave { background: linear-gradient(55.56deg, rgba(46, 186, 198, 0.2) -0.13%, rgba(182, 80, 158, 0.2) 92.85%); }
  &.compound { background: linear-gradient(210.01deg, rgba(1, 211, 149, 0.2) 7.38%, rgba(10, 230, 137, 0.2) 91.91%); }
  &.liquity { background: linear-gradient(210.09deg, rgba(46, 182, 234, 0.2) 7.32%, rgba(116, 93, 223, 0.2) 91.89%); }
  &.chicken-bonds { background: linear-gradient(210.17deg, rgba(60, 94, 186, 0.2) 7.34%, rgba(22, 52, 134, 0.2) 91.95%); }

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    img {
      margin-right: 8px;
      vertical-align: top;
    }

    .subtitle {
      margin-left: 10px;
      font-size: 16px;
      line-height: 16px;
      color: rgba(white, .5);
    }
  }
  &.large {
    h3 {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .button {
    margin-top: 24px;
  }

  .data-row {
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(white, .2);
    }
  }
}
