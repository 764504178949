.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  padding: 8px;
  font-size: 12px;
  background: #61717E;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 12px;
  border-radius: 3px;
  text-decoration: none;
  //@include hover-transition;

  //&:disabled {
  //  user-select: none;
  //  cursor: not-allowed;
  //  background: $gray-7;
  //  color: $gray-7-disabled-text;
  //}

  //&:hover:not(:disabled) { background: darken($gray-7, 5%); }
  //&:active:not(:disabled) { background: darken($gray-7, 10%); }

  &.transparent {
    background: transparent;
    color: white;

    &:disabled {
      background: transparent;
      color: #89939D;
    }

    &:hover:not(:disabled) {
      background: transparent;
      opacity: .7;
    }
    &:active:not(:disabled) {
      opacity: .6;
    }
  }
}
