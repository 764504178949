.automation-wrapper {
  .loader-wrapper {
    @media (max-width: 1140px) {
      padding: 0 16px;
    }
  }
  .aggregated-data {
    display: flex;
    width: 100%;
    margin-bottom: 72px;
    flex-wrap: wrap;
    .data-item-wrapper {
      padding: 24px 16px;
      .value-wrapper {
        max-width: none;
      }
    }
    .spacer:not(:last-child) {
      flex-basis: 24px !important;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 24px;
    @media (max-width: 1140px) {
      padding: 0 16px;
    }
  }

  .data-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    margin-bottom: 72px;
    gap: 24px;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 600px) {
      padding: 0 16px;
    }
  }

  hr {
    border-color: rgba(white, .1);
    width: 100%;
    box-sizing: border-box;
  }
}
