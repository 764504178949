//@import 'common/colors';
//@import 'common/variables';

.contracts-wrapper {
  .loader-wrapper {
    @media (max-width: 1140px) {
      padding: 0 16px;
    }
  }

}
