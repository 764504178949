@import 'colors';

.table-wrapper{
  max-width: 100%;
  width: 100%;
  overflow: auto;

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;

    th, td {
      text-align: left;
    }

    thead {
      @media (max-width: 900px) {
        display: none;
      }
      tr {
        border-bottom: 1px solid $gray;
      }
      th {
        color: $gray;
        font-weight: 400;
        padding: 8px 16px;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid $element-bg-3;
        @media (max-width: 900px) {
          display: flex;
          flex-direction: column;
          padding: 16px;
          gap: 16px;
          position: relative;
          td {
            padding: 0;
          }
          .explorer-link {
            position: absolute;
            top: 16px;
            right: 16px;
          }
        }

        &.has-additional {
          border-bottom: 1px dashed $element-bg-3;
        }
      }
      td {
        padding: 16px;
        vertical-align: top;

        .subtitle {
          color: $gray;
          margin-top: 12px;
          font-size: 14px;
          @media (max-width: 900px) {
            margin-top: 4px;
          }
        }
        .timestamp {
          display: inline-block;
          white-space: nowrap;
          color: $gray;
          font-size: 14px;
        }
        .tags {
          display: flex;
          gap: 8px;
          flex-wrap: wrap;
        }
        .tag {
          display: inline-block;
          white-space: nowrap;
          background-color: $element-bg-3;
          font-size: 14px;
          border-radius: 12px;
          //padding: 4px 8px 4px 4px;
          //line-height: 16px;
          padding: 0 8px 0 0;
          line-height: 24px;
          img {
            vertical-align: bottom;
            margin-right: 8px;
          }
        }
      }
    }

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
