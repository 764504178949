@import "../../layout/app/colors";

.data-item-wrapper  {
  position: relative;
  color: $gray;

  .label-wrapper {
    display: flex;
    align-items: center;

    .icon {
      font-size: 16px;
      margin-right: 6px;
      vertical-align: baseline;

      &:hover:before { color: rgba(#fff, 0.8); }

      &:before {
        transition: all .2s;
        color: rgba(#fff, 0.5);
      }
    }

    .after-value {
      display: inline-block;
      .amount-wrapper { display: inline-flex; }
      .after { display: none; }
      .amount { font-size: inherit !important; }
    }
  }

  .label { margin-bottom: 1px; }

  .value-wrapper {
    font-size: 42px;
    color: white;
    font-weight: 300;
    max-width: 240px;

    .automation-shield-icon-connected {
      vertical-align: top;
      height: 52px;
      svg {
        margin: 3px 0;
      }
    }

    .symbol {
      &.small {
        font-size: 2rem;
        color: $gray;
        margin-left: 2px;
      }
    }
  }

  > .after-value {
    .amount-wrapper {
      display: flex;
      align-items: center;

      .after {
        margin-right: 5px;
      }

      .amount {
        font-size: 20px;
      }
    }
  }

  &.standard {
    > .after-value {
      height: 30px;
      //@media only screen and (max-width: 650px) {
      //  height: auto;
      //}
    }
  }
  &.standard + .spacer:not(:last-child) {
    flex: 0 1 40px;
    min-width: 5px;
  }

  &.standard-smaller {
    .value-wrapper {
      font-size: 2.5rem;
      .symbol.small {
        font-size: 1.4rem;
      }
    }
  }

  // TYPE SMALL
  &.small {
    .label-value-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label-wrapper {
        margin-right: 5px;
        margin-bottom: 0;

        .icon {
          font-size: 12px;
          vertical-align: baseline;
        }
        .label { font-size: 15px; }
      }

      .value-wrapper {
        font-size: 25px;
      }
    }

    .after-value {
      margin-top: 3px;

      .amount-wrapper {
        align-items: center;
        justify-content: space-between;

        .after { margin-right: 5px; }
        .amount { font-size: 14px + 0.1; }
      }
    }
  }

  // TYPE ARROW
  &.arrow {
    display: flex;
    align-items: center;

    .label-value-wrapper {
      display: flex;
      align-items: center;

      .label-wrapper {
        margin-bottom: 0;
        margin-right: 10px;

        .label {
          font-size: 14px;
          color: $gray;
        }
      }

      .value-wrapper { font-size: 18px; }
    }

    .arrow-icon {
      margin: 0 10px;
      flex: 1 0 auto;
    }

    > .after-value {
      margin-top: 0;

      .amount-wrapper {

        .after { display: none; }
        .amount  { font-size: 18px; }
      }
    }
  }

  @media only screen and (max-width: 650px) {
    width: 100%;
    &.standard {
      + .spacer {
        flex-basis: 0 !important;
      }
      padding: 10px 20px !important;
    }
    &.highlighted {
      padding: 20px !important;
    }

    .label-value-wrapper {
      flex: 1 0 auto;
      .value-wrapper {
        .automation-shield-icon-connected {
          position: absolute;
          right: 10px;
          top: 10px;
          height: 90px;
          svg {
            height: 80px;
            width: auto;
          }
        }
      }
    }

    &.standard, &.highlighted, &.small {
      > .after-value {
        height: auto;
        width: 100%;
        position: static;
        margin-top: 2px;
      }
    }
  }
}
