@import '../app/colors';

.footer-wrapper {
  color: white;
  font-size: 14px;
  padding: 65px 20px;
  background: transparent;

  .width-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .company-info-wrapper {
      display: flex;
      align-items: center;
      .company-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        .developed {
          margin-top: 4px;
          font-size: 14px;
        }

        .links-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;

          .link {
            color: white;
            transition: all .3s;
            cursor: pointer;
            text-decoration: underline;
            margin: 0;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }

    .social-media-links-wrapper {
      text-align: right;
      color: $gray;

      .label {
        margin-bottom: 10px;
      }

      .icons-wrapper {

        a{
          opacity: 1 !important;
          i {
            width: 100px !important;
            height: 100px !important;
            border-radius: 100%;
            background: #1D2024;
            margin-left: 10px;
            font-size: 35px !important;
            transition: all .3s;
            cursor: pointer;


            &:hover {
              background: lighten(#161F26, 2%);
            }
          }
        }
      }
    }

    @media only screen and (max-width: 500px) {
      flex-direction: column-reverse;

      .social-media-links-wrapper {
        margin-bottom: 40px;

        a {
          margin: 0 7px;
        }

        .label {
          text-align: center;
        }

        .icons-wrapper i {
          margin: 0;
        }
      }
    }

    @media only screen and (max-width: 360px) {
      .company-info-wrapper {
        flex-direction: column;

        .logo {
          margin: 0 0 20px 0;
        }

        .company-info .links-wrapper .link {
          text-align: center;
        }
      }
    }
  }
}


.new-discord{
  &::before {
    content: url('./new-icons/ic-discord.svg');
    position: relative;
    top: 12px;
  }
}

.new-github{
  &::before {
    content: url('./new-icons/ic-github.svg');
    position: relative;
    top: 12px;
  }
}

.icon-defisaver{
  &::before {
    content: url('./new-icons/ic-defisaver.svg');
    position: relative;
    top: 12px;
  }
}

.new-twitter{
  &::before {
    content: url('./new-icons/ic-twitter.svg');
    position: relative;
    top: 12px;
  }
}

.defi-saver{
  &::before {
    content: url('./new-icons/defi-saver.svg');
    position: relative;
    top: 9px;
    margin-left: 5px;
  }
}
