@import 'colors';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

html, body {
  background: $element-bg-2;
  color: $text-primary;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

.width-container {
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

h1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  @media (max-width: 1140px) {
    padding: 0 16px;
  }
}

.app {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
  }

  > .page-content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #20292F, #20292F00) no-repeat;
    background-size: 100% 200px;
    font-size: 14px;
    > div { flex-grow: 1; }
  }
}

.mobile-only {
  @media (min-width: 601px) {
    display: none;
  }
}
.desktop-only {
  @media (max-width: 600px) {
    display: none;
  }
}

@import 'buttons';
@import 'table';
