@import '../layout/app/colors';

.feed-wrapper {
  .filters-wrapper {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    //align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    gap: 24px;
    .filter-wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;
      @media (max-width: 600px) {
        width: 100%;
      }
      
    }
    .loader-wrapper {
      flex: 1 1 auto;
      line-height: 36px;
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
      @media (max-width: 1140px) {
        padding: 0 16px;
      }
    }
    .spacer {
      margin: 0 16px;
      border-left: 1px solid $gray;
      height: 66px;
      @media (max-width: 600px) {
        display: none;
      }
    }
    @media (max-width: 600px) {
      padding: 0 16px;
    }
  }

  .ui.dropdown {
    padding: 3px 8px;
    border-radius: 3px;
    transition: all .2s;
    line-height: 20px;
    &:hover, &.active {
      background-color: rgba(white, .1);
    }
    .menu.visible {
      display: block !important;
      background-color: $element-bg-3;
      .item {
        color: $gray;
        line-height: 20px;
        font-size: 14px;
        &.selected {
          color: white;
          background-color: rgba(black, .1);
        }
        &:hover {
          background-color: rgba(black, .2);
        }
      }
    }
    .image {
      height: 24px;
      margin-top: -2px !important;
      margin-bottom: -2px !important;
      margin-right: 8px !important;
    }
    &.multiple {
      padding: 3px 8px 3px 3px;
      > .label {
        margin: 0 4px 0 0;
        line-height: 24px;
        display: inline-flex;
        align-items: center;
        padding: 3px 6px;
        .delete.icon:before {
          content: 'x';
          font-size: 12px;
          font-weight: 700;
          color: $gray;
          font-style: normal;
          margin-left: 4px;
        }
      }
    }
    .dropdown.icon {
      vertical-align: top;
      line-height: 30px;
    }
    .default.text {
      color: white !important;
    }
  }

  .leverage-label {
    color: $gray;
  }

  .label-after-data {
    font-size: 14px;
    width: 100%;
    text-align: center;
    color: $gray;
    line-height: 32px;
  }
}

