.breadcrumbs {
  display: flex;
  color: darken(white, 20%);

  @media (max-width: 1140px) {
    padding: 0 0 0 16px;
  }

  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    &::before {
      display: none;
    }
  }

  :last-child {
    padding-right: 40px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: lighten(#2b3943, 10%) !important;
    color: #fff !important;

    &::before {
      content: "";
      border-top: 12px solid lighten(#2b3943, 10%) !important;
      border-bottom: 12px solid lighten(#2b3943, 10%) !important;
      border-left: 8px solid transparent;
      position: absolute;
      left: -8px;
      top: 0;

      @media (max-width: 1140px) {
        border-top: 18px solid lighten(#2b3943, 10%) !important;
        border-bottom: 18px solid lighten(#2b3943, 10%) !important;
      }
    }

    &::after {
      display: none;
    }

    &:hover {
      cursor: default !important;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(-2rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .crumb {
    display: block;
    float: left;
    height: 16px;
    background: #2b3943;

    text-align: center;
    padding: 4px 6px 4px 6px;
    position: relative;
    margin: 0 14px 0 0;

    font-size: 16px;
    line-height: 16px;
    text-decoration: none;

    animation: fadeIn 0.2s ease-in-out;

    @media (max-width: 1140px) {
      display: flex;
      align-items: center;
      height: 28px;
      margin: 0 12px 0 0;
    }

    &:hover {
      background: lighten(#2b3943, 10%);
      color: #fff;
      cursor: pointer;

      &::after {
        border-left: 8px solid lighten(#2b3943, 10%);
        color: #fff;
      }

      &::before {
        border-top: 12px solid lighten(#2b3943, 10%);
        border-bottom: 12px solid lighten(#2b3943, 10%);
        color: #fff;

        @media (max-width: 1140px) {
          border-top: 18px solid lighten(#2b3943, 10%);
          border-bottom: 18px solid lighten(#2b3943, 10%);
        }
      }
    }

    &::after {
      content: "";
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 8px solid #2b3943;
      position: absolute;
      right: -8px;
      top: 0;
      z-index: 1;

      @media (max-width: 1140px) {
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
      }
    }
    &::before {
      content: "";
      border-top: 12px solid #2b3943;
      border-bottom: 12px solid #2b3943;
      border-left: 8px solid transparent;
      position: absolute;
      left: -8px;
      top: 0;

      @media (max-width: 1140px) {
        border-top: 18px solid #2b3943;
        border-bottom: 18px solid #2b3943;
      }
    }
  }
}
