@import '../app/colors';

.header-wrapper {
  padding: 32px 0;
  background: linear-gradient(to bottom, #20292F, #20292F00) $element-bg-2 no-repeat;
  background-size: 100% 100px;
  border-bottom: 1px solid #313D47;
  position: sticky;
  top: 0;
  z-index: 10;
  @media (max-width: 1140px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
  .logo {
    height: 28px;
    width: 116px;
    background-image: url("./logo.svg");
    background-size: cover;
    @media (max-width: 600px) {
      width: 28px;
    }
  }
  .header-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  nav {
    display: flex;
    gap: 20px;
    a {
      color: #939DA7;
      text-decoration: none;
      &.active {
        color: white;
      }
    }
  }
}
